import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "../index.css";
import App from '../App';
import WebLanding from "../screens/WebLanding"; 
import RestaurantDetails from "../screens/RestaurantDetails";
import RelatableItems from "../components/RelatableItems";
import ItemDetails from "../components/ItemDetails";
import OrderSuccesspage from "../screens/OrderSuccesspage";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsAndConditions from "../components/TermsAndConditions";
import { ThemeProvider } from "../components/ThemeContext"; 


const Routing = () => {

  return (
    <Router>
      <ThemeProvider> 
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/WebLanding" element={<WebLanding />} /> 
          <Route path="/restaurantdetails/:id" element={<RestaurantDetails />} />
          <Route path="/restaurantdetails/:id/:itemId" element={<ItemDetails />} />
          <Route path="/RelatableItems" element={<RelatableItems />} />
          <Route path="/OrderSuccesspage" element={<OrderSuccesspage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndConditions />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
};

export default Routing;
